import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Aquest bolet es caracteritza per la seva olor a ferro. Té un capell de fins a 4 cm de diàmetre, primer acampanat i amb els marges aferrats al peu, que amb el temps s’obre fins acabar estès. Aquest presenta un disc central de color marró castany a un poc rogenc dissociat cap als marges en forma d’esquames i formant centres concèntrics. La cutícula excedeix el marge del capell. Les nombroses làmines estan separades i són de color blanc tornant-se crema groguenc amb el temps. El peu és més gruixut cap a la base on presenta un miceli cotonós amb rizomorfs blancs i tot ell és blanc, tornant-se color carn. Presenta un anell membranós fugaç. Les espores són blanques en massa, el·líptiques, amb un esperó a un dels caps, de 6-8 x 5-3,5 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      